import React from 'react'

import Layout from '../containers/Layout'
import Container from '../components/Container'
import Text from '../components/Text'
import VerticalCenter from '../components/VerticalCenter'
import { mobileOrDesktop } from '../components/ThemeProvider/theme'

const IndexPage = () => (
  <Layout noHeader>
    <VerticalCenter>
      <Container py="2em">
        <Text align="center" fontSize={mobileOrDesktop('1em', '1.5em')}>Hello</Text>
      </Container>
    </VerticalCenter>
  </Layout>
);

export default IndexPage
